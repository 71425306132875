import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion'
import { NgClass, NgTemplateOutlet, NgStyle } from '@angular/common'
import { Component, ContentChild, ElementRef, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { MatMenuTrigger, MatMenuModule } from '@angular/material/menu'

import { IconFill, Icon } from '~icons'

import { ButtonComponent } from '../button/button.component'

export type MenuItem = {
  icon?: IconFill | Icon
  label: string
}

@Component({
  selector: 'sb-menu',
  template: `
    <!-- Menu trigger -->
    <div
      [matMenuTriggerFor]="menu"
      #trigger="matMenuTrigger"
      [matMenuTriggerData]="menuData"
      [ngClass]="{ 'w-full': fill }"
    >
      <ng-container
        [ngTemplateOutletContext]="{ $implicit: selected }"
        [ngTemplateOutlet]="labelTemplateRef || defaultLabelTemplate"
      ></ng-container>
    </div>

    <!-- Menu content -->
    <mat-menu #menu="matMenu" [xPosition]="xPosition" [yPosition]="yPosition">
      <ng-template matMenuContent let-menuWidth="menuWidth">
        <div [ngStyle]="{ width: fill && menuWidth }">
          @for (option of options; track option; let i = $index) {
            @if (!optionTemplateRef) {
              <div
                (click)="option['children']?.length > 0 ? null : onSelectionChanged($event, option)"
                mat-menu-item
                [matMenuTriggerFor]="option['children']?.length > 0 ? subMenu : null"
                [matMenuTriggerData]="option"
              >
                @if (option['icon']) {
                  <mat-icon [svgIcon]="option['icon']" />
                }
                <span>{{ option[optionLabelKey] }}</span>
              </div>
            } @else {
              <div (click)="onSelectionChanged($event, option)" mat-menu-item>
                <ng-container
                  [ngTemplateOutletContext]="{ $implicit: option, index: i }"
                  [ngTemplateOutlet]="optionTemplateRef"
                />
              </div>
            }
          }
        </div>
      </ng-template>
    </mat-menu>
    <mat-menu #subMenu="matMenu">
      <ng-template matMenuContent let-children="children">
        @for (option of children; track option) {
          <div (click)="onSelectionChanged($event, option)" mat-menu-item>
            @if (option['icon']) {
              <mat-icon [svgIcon]="option['icon']" />
            }
            <span>{{ option[optionLabelKey] }}</span>
          </div>
        }
      </ng-template>
    </mat-menu>

    <ng-template #defaultLabelTemplate>
      <sb-button variant="menu">
        {{ label }}
      </sb-button>
    </ng-template>
  `,
  styles: ['.mat-mdc-panel.fill { width: 100%; }'],
  standalone: true,
  imports: [MatMenuModule, NgClass, NgTemplateOutlet, NgStyle, MatIconModule, ButtonComponent],
})
export class MenuComponent<T> {
  @Input() optionLabelKey = 'label'
  @Input() label?: string
  @ContentChild('labelTemplate') labelTemplateRef?: TemplateRef<unknown>
  @ContentChild('optionTemplate') optionTemplateRef?: TemplateRef<unknown>
  @Input() options: T[] = []
  @Input() selected: T = null
  @Output() selectionChanged = new EventEmitter<T>()
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger
  @ViewChild(MatMenuTrigger, { static: true, read: ElementRef }) triggerElementRef: ElementRef<HTMLElement>
  @Input() xPosition: 'before' | 'after' = 'after'
  @Input() yPosition: 'above' | 'below' = 'below'
  protected readonly menubar = menubar
  private _fill = false
  private _panelWidthAsTrigger = false
  private _selectable = false

  @Input()
  get fill(): boolean {
    return this._fill
  }

  set fill(value: BooleanInput) {
    this._fill = coerceBooleanProperty(value)
  }

  get menuData() {
    return {
      menuWidth: `${this.triggerElementRef.nativeElement.clientWidth}px`,
    }
  }

  @Input()
  get panelWidthAsTrigger(): boolean {
    return this._panelWidthAsTrigger
  }

  set panelWidthAsTrigger(value: BooleanInput) {
    this._panelWidthAsTrigger = coerceBooleanProperty(value)
  }

  @Input()
  get selectable(): boolean {
    return this._selectable
  }

  set selectable(value: BooleanInput) {
    this._selectable = coerceBooleanProperty(value)
  }

  onSelectionChanged($event: MouseEvent | TouchEvent, option: T): void {
    if (!this.selectable) {
      return
    }

    $event.stopPropagation()
    $event.preventDefault()
    this.selected = option
    this.selectionChanged.emit(option)
    this.trigger.closeMenu()
  }
}
